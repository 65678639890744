import './polyfills';

// Components
import Article from './components/article';
import BackgroundVideo from './components/background-video';
import Carousel from './components/carousel';
import IssueHeader from './components/issue-header';
import IssueToc from './components/issue-toc';
import Nav from './components/nav';
import OfficeDisplaysCarousel from './components/office-displays-carousel';
import OfficeLayout from './components/office-layout';

// Utilities
import { instantiate } from './utilities/components';

/* eslint-disable quote-props */
const classMap = {
    'article': Article,
    'background-video': BackgroundVideo,
    'carousel': Carousel,
    'issue-toc': IssueToc,
    'issue-header': IssueHeader,
    'nav': Nav,
    'office-displays-carousel': OfficeDisplaysCarousel,
    'office-layout': OfficeLayout,
};

// Event handler functions
function handleDOMContentLoaded() {
    // Call component constructors
    instantiate(document.body, classMap);
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
